<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const touchStartX = ref(0);
const touchEndX = ref(0);
const swipeDirection = ref('');

const handleTouchStart = (event) => {
  touchStartX.value = event.changedTouches[0].clientX;
};

const handleTouchEnd = (event) => {
  touchEndX.value = event.changedTouches[0].clientX;
  const swipeThreshold = 50;

  if (touchStartX.value - touchEndX.value > swipeThreshold) {
    swipeDirection.value = 'next';
    setTimeout(() => {
      router.push('/sixthIhram');
    }, 300);
  } else if (touchEndX.value - touchStartX.value > swipeThreshold) {
    swipeDirection.value = 'prev';
    setTimeout(() => {
      router.push('/fourthIhram');
    }, 300);
  }
};
</script>

<template>
  <section class="background" @touchstart="handleTouchStart" @touchend="handleTouchEnd">
  <div class="container">
      <div class="header_block">
        <router-link to="">
          <img src="@/assets/img/icons/prev.svg">
        </router-link>
        <h2 class="title">Ихрам</h2>
        <div></div>
      </div>

      <div class="content">
        <div class="info">
          <p class="text">6. Находящимся в ихраме запрещено сва- таться или жениться. Также запрещены при- косновения к жене с вожделением и половая близость.</p>
          <i class="text text_green">На что указывает хадис Усмана (да будет доволен им Аллах!), что пророк сказал: </i>
          <i class="text text_green">«Находящийся в ихраме не женится, не женит и не сватается»</i>
          <p class="text_orange center">(Муслим)</p>
          <p class="text">7. Женщине в ихраме запрещено надевать перчатки, закрывать лицо паранджой или никабом. но она может прикрываться чем-нибудь, к примеру краем платка, когда окажется рядом с посторонними мужчинами.</p>
          <p class="text">8. Мужчине, находящемуся в ихраме, зап- рещено покрывать голову тюбетейкой, чалмой, накидкой и т.д. Если же это произошло по забывчивости или по нез- нанию, то паломник обязан, как только вспомнит или узнает об ошибке, снять голов- ной убор. искупительных действий от него не требуется.</p>
          <p class="text">9. Находящемуся в ихраме запрещено на- девать сшитую одежду, например: рубашку, брюки, туфли, нижнее белье и т.д. Если же у человека нет изара (куска материи, обво- рачиваемого вокруг нижней части тела), то ему разрешается надеть брюки. А тому, кто не нашел сандалий, можно носить туфли.</p>
        </div>
        <div class="pagination">
          <span >{{ 5 }}/{{ 6 }}</span>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.content {
  position: relative;
}
.info {
  & .text:not(:first-child) {
    padding-top: 16px;
  }
}
.center {
  text-align: center;
  padding-top: 16px;
  font-style: italic;
}

.pagination {
  position: absolute;
  bottom: 27px;
  left: 50%;
  transform: translateX(-50%);
  color: #525D68;
  display: flex;
  justify-content: center;
  width: 100%;
}
</style>
