<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const touchStartX = ref(0);
const touchEndX = ref(0);

const handleTouchStart = (event) => {
  touchStartX.value = event.changedTouches[0].clientX;
};

const handleTouchEnd = (event) => {
  touchEndX.value = event.changedTouches[0].clientX;
  const swipeThreshold = 50;

  if (touchStartX.value - touchEndX.value > swipeThreshold) {
    setTimeout(() => {
      router.push('/secondIhram');
    }, 300);
  }
};
</script>

<template>
  <section class="background" @touchstart="handleTouchStart" @touchend="handleTouchEnd">
    <div class="container">
      <div class="header_block">
        <router-link to="">
          <img src="@/assets/img/icons/prev.svg">
        </router-link>
        <h2 class="title">Ихрам</h2>
        <div></div>
      </div>

        <div class="content">
          <h2 class="title_pre">ДЕЙСТВИЯ, СОВЕРШАЕМЫЕ ДО<br>ВХОЖДЕНИЯ В ИХРАМ</h2>
          <div class="info">
            <p class="text">1. Следует подстричь ногти, усы и удалить волосы с подмышечных впадин и лобка.</p>
            <p class="text">2. Совершить полное омовение, если есть возможность для этого.</p>
            <p class="text">3. Мужчина полностью снимает с себя всю (сшитую нитями) одежду и облачается в одеяние ихрама.</p>
            <p class="text">4. Женщина не должна надевать паранджу, никаб (вуаль) и перчатки. Но ей разрешается прикрывать свое лицо краем платка при посторонних мужчинах.</p>
            <p class="text">5. После гусля, по возможности, мужчина умащает свое тело благовониями, но не наносит его на одежды ихрама.</p>
            <p class="text">6. После выполнения вышеперечисленного, мусульма- нин делает намерение на Умру. Это намерение и является вхождением в состояние ихрама. Если наступило время обязательного намаза, то лучше вознамериться войти в состояние ихрама после молитвы. В иное же время после ихрама можно совершить два рака’ата намаза.</p>
          </div>
          <div class="pagination">
            <span>{{ 1 }}/{{ 6 }}</span>
          </div>
        </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.content {
  position: relative;
}
.info {
  padding-block: 30px;
  .text:not(:first-child) {
    padding-top: 16px;
  }
}
.tex {
  display: flex;
}
.pagination {
  position: absolute;
  bottom: 27px;
  left: 50%;
  transform: translateX(-50%);
  color: #525D68;
  display: flex;
  justify-content: center;
  width: 100%;
}
</style>
