<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const touchStartX = ref(0);
const touchEndX = ref(0);
const swipeDirection = ref('');

const handleTouchStart = (event) => {
  touchStartX.value = event.changedTouches[0].clientX;
};

const handleTouchEnd = (event) => {
  touchEndX.value = event.changedTouches[0].clientX;
  const swipeThreshold = 50;

  if (touchStartX.value - touchEndX.value > swipeThreshold) {
    swipeDirection.value = 'next';
    setTimeout(() => {
      router.push('/thirdSai');
    }, 300);
  } else if (touchEndX.value - touchStartX.value > swipeThreshold) {
    swipeDirection.value = 'prev';
    setTimeout(() => {
      router.push('/sai');
    }, 300);
  }
};
</script>

<template>
  <section class="background" @touchstart="handleTouchStart" @touchend="handleTouchEnd">
    <div class="container">
      <div class="header_block">
        <router-link to="/sai">
          <img src="@/assets/img/icons/prev.svg">
        </router-link>
        <h2 class="title">Са’й</h2>
        <div></div>
      </div>

        <div class="content">
          <div class="info">
            <p class="text text_arab">لا إله إلا الله، وحده لا شريك له، له الملك، وله الحمد، يحيي ويميت وهو على كل شيء قدير، لا إله إلا الله وحده أنجز وعده، ونصر عبده، وهزم الأحزاب وحده</p>
            <p class="text text-center">«Ля иляха илляллаху уахдаху ля шарикалях. Ляхуль мульку уа ляхуль хамду уа хууа ъаля кули шай ин кадир. Ля иляха илляллаху уахдаху анджаза уаъдаху уа насора ъабдаху уа хазамаль ахзаба уахдаху».</p>

            <div class="info-text">
              <p class="text">Между этими словами паломник просит Всевышнего, о чем пожелает, и если мольба будет короче приведенной формы, это разрешается. Руки поднимаются только при совершении ду’а, при словах же «Аллаху акбар» поднимать их не следует. Частой ошибкой паломников является указывание обеими руками в сторону каабы.</p>
              <p class="text">Далее паломник спускается с Сафы и движется в направлении Марвы обычным шагом, в этот момент человек делает ду’а для себя, семьи и других мусульман. Мужчины, дойдя до зеленых фонарей, переходят на бег до следующих зеленых фонарей, после чего продолжают идти обычным шагом.</p>
              <p class="text"><span class="text_orange">2. Марва.</span> Достигнув Марвы, паломник поворачивается к каабе и произносит те же слова, что и на сафе, за исключением аята. Делает ду’а, о чем пожелает и направляетсяк Сафе. Всякий раз, когда мужчины подходят к</p>
            </div>
          </div>
          <div class="pagination">
            <span >{{ 2 }}/{{ 3 }}</span>
          </div>
        </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.content {
  position: relative;
}
.info {
  .text {
    &:not(:first-child) {
      padding-top: 16px;
    }
    &-center{
      text-align: center;
    }
  }
  &-text {
    padding-top: 24px;
  }
}
.pagination {
  position: absolute;
  bottom: 27px;
  left: 50%;
  transform: translateX(-50%);
  color: #525D68;
  display: flex;
  justify-content: center;
  width: 100%;
}
</style>
