<script setup>
</script>

<template>
  <section class="dua">
    <div class="container dua__container">
      <div class="dua__block">
        <div class="dua__block-text">
          <router-link to="/duaIhram">
            <img src="../../assets/img/ihram.jpg">
             <div class="dua-text">
               <p class="title">Ихрам</p>
               <p class="text">2 дуа</p>
             </div>
          </router-link>
        </div>
        <div class="dua__block-text">
          <router-link to="/duaTalbiya">
            <img src="../../assets/img/talbiya.jpg">
            <div class="dua-text">
              <p class="title">Тальбия</p>
              <p class="text">6 дуа</p>
            </div>
          </router-link>
        </div>
      </div>
      <div class="dua__block">
        <div class="dua__block-text">
          <router-link to="/duaTawaf">
            <img src="../../assets/img/tawaf.jpg">
            <div class="dua-text">
              <p class="title">Таваф</p>
              <p class="text">6 дуа</p>
            </div>
          </router-link>
        </div>
        <div class="dua__block-text">
          <router-link to="/duaSai">
            <img src="../../assets/img/sai.jpg">
            <div class="dua-text">
              <p class="title">Са’й</p>
              <p class="text">10 дуа</p>
            </div>
          </router-link>
        </div>
      </div>
      <div class="dua__block">
        <div class="dua__block-text">
          <router-link to="/duaLiked">
            <img src="../../assets/img/likedDua.jpg">
            <div class="dua-text">
              <p class="title">Избранные дуа</p>
              <p class="text">67 дуа</p>
            </div>
          </router-link>
        </div>
      </div>
      </div>
  </section>
</template>

<style lang="scss" scoped>
.dua {
  background-color: white;
  &__container {
    padding-top: 40px;
    & img {
      border-radius: 10px;
    }
  }
  &__block {
    display: flex;
    justify-content: space-between;
    &:not(last-child) {
      padding-bottom: 26px;
    }
    &-text {
      position: relative;
      .dua-text {
        position: absolute;
        top: 30%;
        left: 20px;
      }
    }
  }
  .text {
    color: white;
    line-height: 19.41px;
    font-size: 16px;
  }
}
</style>
