import { createRouter, createWebHistory } from 'vue-router';
import MikatPage from '@/pages/Mikats/MikatPage.vue';
import SecondMikatPage from '@/pages/Mikats/SecondMikatPage.vue';
import ThirdMikatPage from '@/pages/Mikats/ThirdMikatPage.vue';

import IhramPage from '@/pages/Ihrams/IhramPage.vue';
import SecondIhramPage from '@/pages/Ihrams/SecondIhramPage.vue';
import ThirdIhramPage from '@/pages/Ihrams/ThirdIhramPage.vue';
import FourthIhramPage from '@/pages/Ihrams/FourthIhramPage.vue';
import FifthIhramPage from '@/pages/Ihrams/FifthIhramPage.vue';
import SixthIhramPage from '@/pages/Ihrams/SixthIhramPage.vue';

import TawafPage from '@/pages/Tawafs/TawafPage.vue';
import SecondTawafPage from '@/pages/Tawafs/SecondTawafPage.vue';
import ThirdTawafPage from '@/pages/Tawafs/ThirdTawafPage.vue';
import FourthTawafPage from '@/pages/Tawafs/FourthTawafPage.vue';

import SaiPage from '@/pages/Sais/SaiPage.vue';
import SecondSaiPage from '@/pages/Sais/SecondSaiPage.vue';
import ThirdSaiPage from '@/pages/Sais/ThirdSaiPage.vue';

import DuaPage from '@/pages/Duas/DuaPage.vue';
import DuaIhram from '@/pages/Duas/DuaIhram.vue';
import DuaTalbiya from '@/pages/Duas/DuaTalbiya.vue';
import DuaTawaf from '@/pages/Duas/DuaTawaf.vue';
import DuaSai from '@/pages/Duas/DuaSai.vue';
import DuaLiked from '@/pages/Duas/DuaLiked.vue';

import QuesAns from '@/pages/QA/QuesAns.vue';

import MySuitcase from '@/pages/MySuitcase.vue';
import FirstAidKit from '@/pages/FirstAidKit.vue';

import MapPage from '@/pages/Map/MapPage';
const routes = [
    // Mikat
    {
        path: '/mikat',
        name: 'Mikat',
        component: MikatPage,
    },
    {
        path: '/secondMikat',
        name: 'SecondMikat',
        component: SecondMikatPage
    },
    {
        path: '/thirdMikat',
        name: 'ThirdMikat',
        component: ThirdMikatPage
    },

    // Ihram
    {
        path: '/ihram',
        name: 'Ihram',
        component: IhramPage
    },
    {
        path: '/secondIhram',
        name: 'secondIhram',
        component: SecondIhramPage
    },
    {
        path: '/thirdIhram',
        name: 'thirdIhram',
        component: ThirdIhramPage
    },
    {
        path: '/fourthIhram',
        name: 'fourthIhram',
        component: FourthIhramPage
    },
    {
        path: '/fifthIhram',
        name: 'fifthIhram',
        component: FifthIhramPage
    },
    {
        path: '/sixthIhram',
        name: 'sixthIhram',
        component: SixthIhramPage
    },

    // Tawaf
    {
        path: '/tawaf',
        name: 'tawaf',
        component: TawafPage
    },
    {
        path: '/secondTawaf',
        name: 'secondTawaf',
        component: SecondTawafPage
    },
    {
        path: '/thirdTawaf',
        name: 'thirdTawaf',
        component: ThirdTawafPage
    },
    {
        path: '/fourthTawaf',
        name: 'fourthTawaf',
        component: FourthTawafPage
    },

    // Sai
    {
        path: '/sai',
        name: 'sai',
        component: SaiPage
    },
    {
        path: '/secondSai',
        name: 'secondSai',
        component: SecondSaiPage
    },
    {
        path: '/thirdSai',
        name: 'thirdSai',
        component: ThirdSaiPage
    },

    // Dua
    {
        path: '/dua',
        name: 'dua',
        component: DuaPage
    },
    {
        path: '/duaIhram',
        name: 'duaIhram',
        component: DuaIhram
    },
    {
        path: '/duaTalbiya',
        name: 'duaTalbiya',
        component: DuaTalbiya
    },
    {
        path: '/duaTawaf',
        name: 'duaTawaf',
        component: DuaTawaf
    },
    {
        path: '/duaSai',
        name: 'duaSai',
        component: DuaSai
    },
    {
        path: '/duaLiked',
        name: 'duaLiked',
        component: DuaLiked
    },

    {
        path: '/quesAns',
        name: 'quesAns',
        component: QuesAns
    },
    // Map
    {
        path: '/map',
        name: 'map',
        component: MapPage
    },
    {
        path: '/mySuitcase',
        name: 'mySuitcase',
        component: MySuitcase
    },
    {
        path: '/firstAidKit',
        name: 'firstAidKit',
        component: FirstAidKit
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
